import React, {useState} from 'react';
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import pivotKioskBank from '../../images/locks/product-pages/pivot/pivot-bank-kiosk.png';
import pivotLockBank from '../../images/locks/product-pages/pivot/pivot-bank-lock.png';
import pivotLock from '../../images/locks/product-pages/pivot/pivot-lock.png';
import pivotProLock from '../../images/locks/product-pages/pivot/pivot-pro-lock.png';
import pivotDownCaret from '../../images/locks/product-pages/pivot/pivot-down-caret.png';
import ProductDocuments from "./ProductDocuments";

const PivotAccessOptions = () => {
  const {t} = useTranslation();
  const [pivotChosen, setPivotChosen] = useState(pivotKioskBank);
  const [lockChosen, setLockChosen] = useState(pivotProLock);
  const [labelChosen, setLabelChosen] = useState(t('pivot_with_kiosk'));
  let dsKiosk = 'https://cdn.mediavalet.com/usva/digilock/e0LAAWLi-EupQOrVGHwgqg/mVHh5rXA8k-QxTArl2Cuog/Original/DS-D6PIVOT-KIOSK-DEN.pdf';
  let dsPro = 'https://cdn.mediavalet.com/usva/digilock/HIoCfnYfIU6OxWqLH2IktA/9MIoywM0F06cEu-QgoVZkg/Original/DS-D6PIVOTPRO-DEN.pdf';
  const [dataSheet, setDataSheet] = useState(dsKiosk);
  const handlePivotChosen = (e) => {
    setPivotChosen((prevImage) =>
        prevImage === pivotKioskBank ? pivotLockBank : pivotKioskBank
    );
    setLockChosen((prevImage) =>
        prevImage === pivotLock ? pivotProLock : pivotLock
    );
    setLabelChosen((prevLabel) =>
      prevLabel === t('pivot_with_kiosk') ? t('pivot_pro') : t('pivot_with_kiosk')
    )
    setDataSheet((prevDataSheet) =>
        prevDataSheet === dsKiosk ? dsPro : dsKiosk
    )
  }
  return (
      <div className="container">
        <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('pivot_variations')}}/>
        <div className="grid-two-col pivot">
          <div className="grid-two-col-item-single center access">
            <div className={'pivot-access-labels'}>
              <div
                  data-access={'lock'}
                  className={pivotChosen === pivotLockBank ? 'selected' : ''}
                  onClick={handlePivotChosen}
              >
                <p dangerouslySetInnerHTML={{__html: t('pivot_access_options1')}}/>
                <img src={pivotDownCaret} width={'15'} height={'10'} alt="down arrow"/>
              </div>
              <div
                  data-access={'kiosk'}
                  className={pivotChosen === pivotKioskBank ? 'selected' : ''}
                  onClick={handlePivotChosen}
              >
                <p dangerouslySetInnerHTML={{__html: t('pivot_access_options2')}}/>
                <img src={pivotDownCaret} width={'15'} height={'10'} alt="down arrow"/>
              </div>
            </div>
            <img
                src={pivotChosen}
                width={'194'}
                height={'260'}
                alt="Pivot"
            />
          </div>
          <br className={'spacer'}/>
          <div style={{placeItems:'center'}} className="grid-two-col-item-single page-finish-options">
            <img
                src={lockChosen}
                width={'241'}
                height={'257'}
                alt="Pivot"
            />
            <br/>

            <ProductDocuments
                brochure={'https://cdn.mediavalet.com/usva/digilock/BzAShF0VtkSevKIux6VzWA/WInWcTDN80ykqydFPtbq2g/Original/DIGILOCK-PIVOT-PRODUCT-BROCHURE.pdf'}
                brochureLabel={t('download_brochure')}
                lockLabel={labelChosen}
                dataSheetLabel={t('download_datasheet')}
                dataSheet={dataSheet}
                cls={'pivot'} productName={'pivot'}  />
          </div>
        </div>
        <div className="grid-two-col pivot label">
          <div className="grid-two-col-item-single is-reversable">
            &nbsp;
          </div>
          <div>

          </div>
        </div>
      </div>

  )
}
export default PivotAccessOptions;